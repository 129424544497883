import React from "react";
import {
  StyleSheet,
  Image,
  Alert,
  Text,
  TouchableOpacity,
  ScrollView,
  View,
  ActivityIndicator,
} from "react-native";
import ButtonLinear from "../Buttons/ButtonLinear";
import Container from "../Layout/Container";
import ProfileItem from "../WorkProfileManagement/ProfileItem";
import ButtonIcon from "../Buttons/ButtonIcon";
import { SOURCE_ICON } from "../../images";
import MedicalLicensesItem from "./MedicalLicensesItem";
import { useHistory } from "react-router-dom";
import { Colors, Routes } from "../../configs";
import { height, width } from "../../configs/Const";
import { showModalAction, showModalInfo } from "../../modules/ViewHelpers";
import locales_es from "../../locales/es";
import { api, auth, helpers } from "../../import";
import { EventRegister } from "../../modules/EventRegister";
import { EVENT_RELOAD_MEDICAL_LICENSE } from "../../constants/Events";
import { useState } from "react";
import { set } from "react-native-reanimated";
import { useEffect } from "react";

interface Props {
  style?: any;
  medicalsLicenses?: any[];
  addMedicalLicense?: void | undefined;
  onEditMedicalLicense?: void | undefined | any;
  onSelect?: boolean;
  onSelectLicense?: any;
  loadingLicense?: boolean;
  onSetSelectedLicense?: any;
  onSyncSisa?: void | undefined | any;
}

function MedicalLicenses(props: Props) {
  const history = useHistory();
  const [medicalLicenseSelected, setMedicalLicenseSelected] =
    useState<any>(null);

  useEffect(() => {
    if (medicalLicenseSelected == null && props.onSetSelectedLicense) {
      setMedicalLicenseSelected(props.onSetSelectedLicense);
    }
  }, [props.onSetSelectedLicense]);

  const handlerSelect = (id: any) => {
    setMedicalLicenseSelected(id);
    if (props.onSelectLicense) {
      props.onSelectLicense(id);
    }
  };
  const deleteMedicalLicense = (item: any) => {
    if (item.prescriptions_profiles_count > 0) {
      showModalInfo(
        locales_es.medicalLicenses.title,
        locales_es.medicalLicenseOnUse
      );
    } else {
      confirmDeleteLicense(item.id);
    }
  };

  const confirmDeleteLicense = (id: any) => {
    api
      .deleteMedicalLicense(id)
      .then((response: any) => {
        EventRegister.emit(
          EVENT_RELOAD_MEDICAL_LICENSE,
          "La matrícula fue eliminada con éxito."
        );
      })
      .catch((error: any) => {
        showModalInfo(
          locales_es.medicalLicenses.error.deleteLicenseTitle,
          helpers.getErrorMsg(error)
        );
      });
  };

  const onPressHelp = () => {
    showModalInfo(
      locales_es.medicalLicenses.title,
      locales_es.medicalLicenses.message
    );
  };

  return (
    <Container style={[styles.container, props.style]}>
      <View>
        <ProfileItem
          style={{ ...customProfileItemStyle }}
          icon={"exp"}
          title={"Mis Matrículas"}
          leftButton={
            <TouchableOpacity
              onPress={props.onSyncSisa ? props.onSyncSisa : () => {}}
            >
              <Image
                source={{ uri: SOURCE_ICON.sync, width: 25, height: 25 }}
                style={styles.buttonSetting}
              />
            </TouchableOpacity>
          }
          rightButton={
            <TouchableOpacity onPress={() => onPressHelp()}>
              <Image
                source={{ uri: SOURCE_ICON.help, width: 25, height: 25 }}
                style={styles.buttonHelp}
              />
            </TouchableOpacity>
          }
        >
          <ScrollView style={{ height: "24vh" }}>
            {props.medicalsLicenses?.length &&
              props.medicalsLicenses.map((item: any, index: number) => (
                <MedicalLicensesItem
                  key={index}
                  onSelect={props.onSelect == true ? true : false}
                  medicalLicenseSelected={medicalLicenseSelected}
                  handlerSelect={handlerSelect}
                  deleteMedicalLicense={deleteMedicalLicense}
                  medicalLicense={item}
                  onEditMedicalLicense={props.onEditMedicalLicense}
                />
              ))}
            {props.loadingLicense && !props.medicalsLicenses?.length && (
              <View
                style={{
                  maxHeight: 170,
                  minHeight: 80,
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <ActivityIndicator size="small" color={Colors.BlueCrayola} />
              </View>
            )}
            {!props.loadingLicense && !props.medicalsLicenses && (
              <View
                style={{
                  maxHeight: 170,
                  minHeight: 80,
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{ textAlign: "center", fontSize: 16, color: "black" }}
                >
                  No posee matrículas registradas
                </Text>
              </View>
            )}
          </ScrollView>

          <ButtonLinear
            white
            onPress={props.addMedicalLicense}
            leftChildren={
              <Image
                source={{
                  uri: SOURCE_ICON.add,
                  width: 20,
                  height: 20,
                }}
                style={{ tintColor: "white", marginRight: 8 }}
              />
            }
            style={{
              marginTop: 15,
              width: "92%",
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 22,
            }}
            styleButton={{ marginTop: 0 }}
            colors={[Colors.BlueCrayola, Colors.Blue]}
            title={"Añadir Matrícula"}
          />
        </ProfileItem>
      </View>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "80%",
    width: "100%",
  },
  buttonHelp: {
    tintColor: "#00c1c1",
    color: "#00c1c1",
  },
  buttonSetting: {
    marginRight: "14px",
    tintColor: "#00c1c1",
  },
});
const customProfileItemStyle = StyleSheet.create({
  container: {
    height: 200,
  },
  itemView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default MedicalLicenses;
