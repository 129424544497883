import React, { useState, useCallback, useEffect } from "react";
import { Routes } from "../../../configs";
import { useHistory } from "react-router-dom";
import useModalWithKeyboard from "../../../hooks/useModalWithKeyboard";
import { uploadMyProfileImage } from "../../../modules/ApiHelpers";

import APIService from "../../../modules/apiService";
import AuthService from "../../../modules/authService";
import Helpers from "../../../modules/helpers";
import { EVENT_SHOW_MODAL_INFO } from "../../../constants/Events";
import locales_es from "../../../locales/es";
import { validateEmptyFields } from "../../../utils/validation/forms";
import { EventRegister } from "../../../modules/EventRegister";
import { useTheme } from "../../../configs/Theme";
import { showModalInfo } from "../../../modules/ViewHelpers";
import validateCuitByCountry from "../../../utils/validation/cuit";

export interface IUserData {
  professional_prefix?: { name: string };
  name: string;
  lastname: string;
  idTributario: string;
  country_id: number;
  email: string;
  email_verified_at: string;
  identificationType?: { name: string };
  identification?: string;
  prefix_cell_phone?: string;
  cell_phone?: string;
}

const useBasicInformationPage = () => {
  const history = useHistory();
  const params: any = history.location.state;
  const { theme } = useTheme();

  const api = new APIService();
  const auth = new AuthService();
  const helpers = new Helpers();
  const [errors, setErrors] = useState<any>([]);
  const [load, setLoad] = useState<boolean>(false);
  const [profileMode, setProfileMode] = useState<any>(
    params && params.profileMode ? params.profileMode : false
  );
  const hasSignUp = params && params.hasSignup;
  const [locked, setLocked] = useState<any>(false);
  const [cropping, setCropping] = useState<boolean>(false);
  const [professionalPrefixId, setProfessionalPrefixId] = useState<any>(null);
  const [name, setName] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [idTributario, setIdTributario] = useState("");
  const [cellphone, setCellphone] = useState<any>("");
  const [countryId, setCountryId] = useState<any>(null);
  const [identificationTypes, setIdentificationTypes] = useState<any>([]);
  const [identification, setIdentification] = useState<any>("");
  const [identificationType, setIdentificationType] = useState<any>(null);
  const [sisa, setSisa] = useState<boolean>(false);
  const [professionalPrefixes, setProfessionalPrefixes] = useState<{
    id?: number | null;
    country_id?: number | null;
    name: string;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
  }>({
    id: null,
    country_id: null,
    name: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>("");
  const [profileImage, setProfileImage] = useState<{ uri: string }>({
    uri: "",
  });

  const nameRef = React.createRef();
  const lastnameRef = React.createRef();
  const idTributarioRef = React.createRef();
  const identificationRef = React.createRef();

  const [errorMessages, setErrorMessages] = useState<{
    name: string;
    lastname: string;
    idTributario: string;
    identification: string;
    required: string;
  }>({
    name: "",
    lastname: "",
    idTributario: "",
    identification: "",
    required: "El Campo es obligatorio",
  });
  const [enableButton, setEnableButton] = useState<boolean>(true);

  const [SISAVerification, setSISAVerification] = useState<boolean>(true);

  const [minDocLength, setMinDocLength] = useState<number>(6);
  const [maxDocLength, setMaxDocLength] = useState<number>(8);

  const onPressPrefixSelectorItem = useCallback(
    (professionalPrefixId: number) => {
      setErrors(errors.filter((item: any) => item !== "professionalPrefixId"));
      setProfessionalPrefixId(professionalPrefixId);
    },
    []
  );

  const [validationDataCuit, setValidationData] = useState({
    validateCuit: false,
    requestCuit: false,
    requestIdTributary: false,
  });

  useEffect(() => {
    const fetchValidationData = async () => {
      try {
        const data = await validateCuitByCountry();
        setValidationData(data);
      } catch (error) {
        console.error("Error fetching validation data:", error);
      }
    };

    fetchValidationData();
  }, []);

  const onSubmit = () => {
    let dataSend = {};
    // si el cuit es requerido en este pais
    if (validationDataCuit.requestCuit) {
      dataSend = {
        professionalPrefixId,
        name,
        lastname,
        idTributario,
        identification,
      };
    } else {
      dataSend = {
        professionalPrefixId,
        name,
        lastname,
        identification,
      };
    }

    const errors = validateEmptyFields(dataSend);

    if (errors.length) {
      setErrors(errors);
    } else {
      setLoading(true);
      let objData = {};

      if (validationDataCuit.requestCuit) {
        objData = {
          cell_phone: cellphone,
          professional_prefix_id: professionalPrefixId,
          name,
          lastname,
          idTributario,
          identification_type_id: identificationType.id,
          identification,
          sync_sisa: true,
        };
      } else {
        objData = {
          cell_phone: cellphone,
          professional_prefix_id: professionalPrefixId,
          name,
          lastname,
          identification_type_id: identificationType.id,
          identification,
          sync_sisa: true,
        };
      }

      auth
        .updateUser(objData)
        .then((res) => {
          setLoading(false);
          profileMode
            ? history.goBack()
            : history.push(`/${Routes.WorkProfile}`, {
                hasSignup: !!hasSignUp,
                firstProfile: true,
              });
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.successModal.title,
            message: res.message,
          });
        })
        .catch((err) => {
          setLoading(false);
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.infoModal.title,
            message: helpers.getErrorMsg(err),
          });
        });
    }
  };

  const handleValidatorInputsTypes = (
    value: any,
    type: string = "",
    setState: any,
    id: string,
    minLength: number = 0
  ) => {
    var allErrors = errors.filter((item: any) => item !== id);
    setErrors(errors.filter((item: any) => item !== id));

    if (type === "cuit") {
      // Solo permitir números y guiones
      let cleanValue = value.replace(/[^0-9]/g, ""); // Elimina todo excepto los dígitos

      if (cleanValue.length > 2) {
        cleanValue = cleanValue.slice(0, 2) + "-" + cleanValue.slice(2);
      }
      if (cleanValue.length > 11) {
        cleanValue = cleanValue.slice(0, 11) + "-" + cleanValue.slice(11);
      }

      cleanValue = cleanValue.slice(0, 13);
      setIdTributario(cleanValue);

      if (cleanValue.length != 13) {
        setErrors([...errors, id]);
        setErrorMessages({
          ...errorMessages,
          [id]: "El Campo debe contener solo 11 caracteres.",
        });
      } else {
        const newErrors = errors.filter((e: any) => e !== id);
        setErrors(newErrors);
      }

      setState(cleanValue);
      setEnableButton(handleValidateEmptyFields(allErrors));
      return;
    }

    if (
      value.length > 0 &&
      value.length >= minLength &&
      value.trim().length > 0
    ) {
      if (type === "letter") {
        const regex =
          /^[a-zA-ZÀ-ÿ\u00f1\u00d1\u014c\u014d\u016a\u016b\u016c\u016d\u016e\u016f\u0170\u0171\u0172\u0173\u0174\u0175\u0176\u0177\u0178\u0179\u017a\u017b\u017c\u017d\u017e\u012a\u012b\u012c\u012d\u012e\u012f\u0130\u0131 ']*$/;
        if (!regex.test(value)) {
          setState(value);
          setErrors([...errors, id]);
          allErrors.push(id);
          setErrorMessages({
            ...errorMessages,
            [id]: "El Campo solo puede contener letras",
          });
          setEnableButton(handleValidateEmptyFields(allErrors));
          return;
        }
        setState(value);
        setEnableButton(handleValidateEmptyFields(allErrors));
        return;
      }
      if (type === "number") {
        const regex = /^[0-9]*$/;
        if (!regex.test(value)) {
          setState(value);
          setErrors([...errors, id]);
          allErrors.push(id);
          setErrorMessages({
            ...errorMessages,
            [id]: "El Campo solo puede contener números",
          });
          setEnableButton(handleValidateEmptyFields(allErrors));
          return;
        }
        setState(value);
        setEnableButton(handleValidateEmptyFields(allErrors));
        return;
      }

      if (type === "cuit") {
        const cuitRegex = /^\d{2}-\d{8}-\d{1}$/;
        if (!cuitRegex.test(value)) {
          setState(value);
          setErrors([...errors, id]);
          allErrors.push(id);
          setErrorMessages({
            ...errorMessages,
            [id]: "Formato de CUIT inválido. El formato correcto es 00-00000000-0",
          });
          setEnableButton(handleValidateEmptyFields(allErrors));
          return;
        }
        setState(value);
        setEnableButton(handleValidateEmptyFields(allErrors));
        return;
      }

      setState(value);
      setEnableButton(handleValidateEmptyFields(allErrors));
      return;
    } else if (
      value.length > 0 &&
      value.length < minLength &&
      value.trim().length > 0
    ) {
      if (type === "letter") {
        const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$/;
        if (!regex.test(value)) {
          setState(value);
          setErrors([...errors, id]);
          allErrors.push(id);
          setErrorMessages({
            ...errorMessages,
            [id]:
              "El Campo solo puede contener letras y debe contener al menos " +
              minLength +
              " caracteres",
          });
          setEnableButton(handleValidateEmptyFields(allErrors));
          return;
        }
      }
      if (type === "number") {
        const regex = /^[0-9]*$/;
        if (!regex.test(value)) {
          setState(value);
          setErrors([...errors, id]);
          allErrors.push(id);
          setErrorMessages({
            ...errorMessages,
            [id]:
              "El Campo solo puede contener números y debe contener al menos " +
              minLength +
              " caracteres",
          });
          setEnableButton(handleValidateEmptyFields(allErrors));
          return;
        }
      }
      setState(value);
      setErrors([...errors, id]);
      allErrors.push(id);
      setErrorMessages({
        ...errorMessages,
        [id]: "El Campo debe contener al menos " + minLength + " caracteres",
      });
      setEnableButton(handleValidateEmptyFields(allErrors));
      return;
    }

    setState("");
    setErrors([...errors, id]);
    allErrors.push(id);
    setErrorMessages({ ...errorMessages, [id]: "El Campo es obligatorio" });
    setEnableButton(handleValidateEmptyFields(allErrors));
  };

  const handleValidateEmptyFields = (errors: any) => {
    let getErrors;

    if (validationDataCuit.requestCuit) {
      getErrors = validateEmptyFields({
        name,
        lastname,
        idTributario,
        identification,
      });
    } else {
      getErrors = validateEmptyFields({
        name,
        lastname,
        identification,
      });
    }
    if (getErrors.length > 0 || errors.length > 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {}, [idTributario]);

  const {
    visible: visibleIdentificationTypesMenuOption,
    open: openIdentificationTypesMenuOption,
    close: closeIdentificationTypesMenuOption,
  } = useModalWithKeyboard(false);

  const onBlur = (inputStr: string) => {
    setErrors(errors.filter((item: any) => item !== inputStr));
  };

  useEffect(() => {}, [idTributario]);

  useEffect(() => {
    api
      .getUserByMe()
      .then((res) => {
        if (res && res.data && res.data.user) {
          const _countryId = res.data.user.country_id;
          const _userData = res.data.user;
          setProfileImage({ uri: _userData.full_profile_image });
          setProfessionalPrefixId(_userData.professional_prefix_id);
          setName(_userData.name);
          setLastname(_userData.lastname);
          setIdTributario(_userData.idTributario);
          setCellphone(_userData.cell_phone);
          setCountryId(_countryId);
          setIdentification(_userData.identification);

          let activate;
          // si el cuit es requerido en este pais
          if (!validationDataCuit.requestCuit) {
            activate =
              _userData.name &&
              _userData.lastname &&
              _userData.identification &&
              _userData.cell_phone;
          } else {
            activate =
              _userData.name &&
              _userData.lastname &&
              _userData.idTributario &&
              _userData.identification &&
              _userData.cell_phone;
          }

          if (activate) {
            setEnableButton(false);
          }
          if (_countryId === 1 && !activate) {
            showModalSISAInfo();
          }
          api
            .getIdentificationTypesByCountryId(_countryId)
            .then((res2: any) => {
              if (res2 && res2.data && res2.data.length) {
                setIdentificationTypes(res2.data);

                const userIdType = _userData.identification_type_id;
                if (userIdType) {
                  const idType = res2.data.filter(
                    (type: any) => Number(type.id) === Number(userIdType)
                  )[0];
                  setIdentificationType(idType);
                  setMinDocLength(idType.min);
                  setMaxDocLength(idType.max);
                } else {
                  setIdentificationType(res2.data[0]);
                  setMinDocLength(res2.data[0].min);
                  setMaxDocLength(res2.data[0].max);
                }
              }
            })
            .catch((err2: any) => {
              EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
                title: locales_es.infoModal.title,
                message: helpers.getErrorMsg(err2),
              });
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    api
      .getUserProfessionalPrefixes()
      .then((res) => {
        if (res && res.data) {
          setProfessionalPrefixes(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [load]);

  const showNonEditableInfo = () => {
    EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
      title: locales_es.basicProfileInfo.title,
      message: locales_es.basicProfileInfo.content,
    });
  };

  const uploadProfileImage = (image: any) => {
    setCropping(false);
    setLoading(true);
    setLoadingText(locales_es.uploadingImage);
    uploadMyProfileImage(image)
      .then(() => {
        setLoading(false);
        setLoadingText("");
        setProfileImage({
          uri: image,
        });
      })
      .catch(() => {
        setLoading(false);
        setLoadingText("");
      });
  };

  const showModalSISAInfo = () => {
    showModalInfo(
      locales_es.SISAInformation.title,
      locales_es.SISAInformation.subtitle
    );
  };

  const handleSISAVerification = (value: string) => {
    setSISAVerification(false);
    auth
      .verificationSISA(value)
      .then((res: any) => {
        if (res.status) {
          setSISAVerification(false);
          return;
        }
        setSISAVerification(true);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return {
    profileMode,
    profileImage,
    uploadProfileImage,
    setCropping,
    professionalPrefixes,
    professionalPrefixId,
    onPressPrefixSelectorItem,
    errors,
    identificationType,
    showNonEditableInfo,
    openIdentificationTypesMenuOption,
    onBlur,
    identificationRef,
    identification,
    nameRef,
    setIdentification,
    setName,
    name,
    lastnameRef,
    lastname,
    idTributarioRef,
    idTributario,
    setIdTributario,
    setLastname,
    onSubmit,
    visibleIdentificationTypesMenuOption,
    closeIdentificationTypesMenuOption,
    setIdentificationType,
    locked,
    identificationTypes,
    loading,
    loadingText,
    cropping,
    hasSignUp: !!hasSignUp,
    handleSISAVerification,
    handleValidatorInputsTypes,
    enableButton,
    SISAVerification,
    minDocLength,
    maxDocLength,
    countryId,
    showModalSISAInfo,
    setMinDocLength,
    setMaxDocLength,
    setSISAVerification,
    errorMessages,
    validationDataCuit,
    sisa,
    setSisa,
  };
};

export default useBasicInformationPage;
